import { axiosBase } from '../_helpers'

export default { postRequest403Unhandled }

function postRequest403Unhandled (url, data) {
  return axiosBase.post(url, data)
    .catch(function (error) {
      throw error
    })
    .then(function (response) {
      return response
    })
}
