<template>
  <div>
    <v-container>
      <v-row style="margin-top: 10vh">
        <v-col cols="12">
          <v-container>
            <ValidationObserver ref="obs" v-slot="{ invalid, validated, passes, validate }">
              <v-card  class="elevation-1 pa-3" >
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="5" class="login-background hidden-md-and-down"></v-col>
                  <v-col cols="12" xs="12" sm="12" md="12" lg="7" style="padding: 3vw 5vw 4vw 5vw">
                    <v-card-text>
                      <div v-if="getClientBrandLogo()" class="layout column align-center">
                        <img :src="getClientBrandImageUrl()" class="logoStyle" alt="logo"/>
                      </div>
                      <div v-else class="layout column align-center">
                        <img alt="Vue Material Admin" src="../assets/eInsights.png" class="eInsightsLogoStyle">
                      </div>
                      <v-form class="mt-2">
                        <div>{{ $t('msg.username_label') }}</div>
                        <ValidationProvider rules="required" name="username"
                                            v-slot="{ errors, valid, failedRules }">
                          <v-text-field append-icon="person" required name="username"
                                        placeholder="Username"
                                        type="text"
                                        outlined
                                        autocomplete="new-password"
                                        :error-messages="$translatedErrorMessages(failedRules,'username')"
                                        :success="valid"
                                        id="username" v-model="username"
                                        v-on:keyup.enter="passes(login)"></v-text-field>
                        </ValidationProvider>

                        <div>{{ $t('msg.password_label') }}</div>
                        <ValidationProvider rules="required" name="password"
                                            v-slot="{ errors, valid, failedRules }">
                          <v-text-field append-icon="lock"
                                        placeholder="********"
                                        name="password"
                                        type="password"
                                        outlined
                                        autocomplete="new-password"
                                        :error-messages="$translatedErrorMessages(failedRules,'password')"
                                        :success="valid"
                                        id="password" v-model="password"
                                        v-on:keyup.enter="passes(login)"></v-text-field>
                        </ValidationProvider>
                        <v-spacer></v-spacer>
                      </v-form>
                    </v-card-text>
                    <v-card-actions class="justify-center pb-5">
                      <v-btn :loading="loading" @click="passes(login)" class="login-button">
                        <v-progress-circular v-show="loading" indeterminate :size="20" :width="3" color="white" class="pr-8"></v-progress-circular>
                        {{$t('msg.login_button')}}
                      </v-btn>
                    </v-card-actions>
                  </v-col>
                </v-row>
              </v-card>
            </ValidationObserver>
          </v-container>
        </v-col>
      </v-row>
      <v-row>
        <v-snackbar v-model="invalidUserCredentials" :timeout="timeout" color="error" right top>
          {{$t('msg.error')}}
        </v-snackbar>
        <v-snackbar v-model="userValidityExpired" :timeout="timeout" color="error" right top>
          {{$t('msg.expired_validity')}}
        </v-snackbar>
      </v-row>
    </v-container>
  </div>
</template>

<script>
// import Header from '../components/Header'
// import Footer from '../components/Footer'
import { userService } from '../_services'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import { apiUrl, rootUrl } from '../_config'

export default {
  name: 'Login',
  components: {
    // Header,
    // Footer,
    ValidationProvider,
    ValidationObserver
  },
  data () {
    return {
      snackbar: false,
      username: '',
      password: '',
      hidePassword: true,
      loading: false,
      invalidUserCredentials: false,
      userValidityExpired: false,
      timeout: 3000,
      overrideColor: ''
    }
  },
  methods: {
    login: function () {
      this.loading = true
      userService.login(this.username, this.password)
        .then(response => {
          this.loading = false
          window.location.href = rootUrl() + 'home'
        }).catch(error => {
          this.loading = false
          console.log(error)
          let showDuplicateError = false
          if (error.response) {
            if (error.response.data) {
              if (error.response.data.expired) {
                showDuplicateError = true
              }
            }
          }
          if (showDuplicateError) {
            this.userValidityExpired = true
          } else {
            this.invalidUserCredentials = true
          }
        })
    },
    getClientBrandImageUrl: function () {
      return apiUrl() + 'media/logo/' + this.getClientBrandLogo()
    },
    getClientBrandLogo: function () {
      if (this.$cookies.get('sp_dashboard_client_logo') && this.$cookies.get('sp_dashboard_client_logo').trim() !== '') {
        return this.$cookies.get('sp_dashboard_client_logo')
      }
      return undefined
    }
  },
  created: function () {
    this.$cookies.set('sp_current_page', '')
    if (this.$cookies.get('sp_dashboard_override_color') && this.$cookies.get('sp_dashboard_override_color').trim() !== '') {
      this.overrideColor = this.$cookies.get('sp_dashboard_override_color')
    }
  }
}
</script>

<style scoped lang="css">
#login {
  height: 50%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
  z-index: 0;
}

.logoStyle {
  max-height: 120px;
  max-width: 314px;
}

.eInsightsLogoStyle {
  width: 316px;
  height: 100px;
}

.v-application a {
  color: #000;
  text-decoration: none;
  opacity: .5;
}

.v-application a:hover {
  opacity: 1;
  text-decoration: underline;
}

.elevation-1 {
  border-radius: 0;
}

.login-button {
  background-color: #2d58ac !important;
  border-color: #2d58ac !important;
  color: white !important;
  font-weight: bold;
  width: 97%;
}

.bnm .v-card {
  background: #6d7b908a;
}

.bnm .login-button {
  background-color: #0A317A !important;
  border-color: #0A317A !important;
  color: white !important;;
}

.bnm .elevation-1 {
  border-top: 3px solid #0A317A
}

.bnm >>> input::placeholder {
  color: white !important;
  opacity: 1;
}

.bnm >>> .v-label {
  color: white;
  opacity: 1;
}

.bnm >>> .v-icon {
  color: white;
  opacity: 1;
}

.bnm >>> .success--text {
  color: #0A317A !important;
  caret-color: #0A317A !important;
}

.mitas .elevation-1 {
  border-top: 3px solid #153830
}

.mitas .login-button {
  background-color: #153830 !important;
  border-color: #0A317A !important;
  color: white !important;;
}

.login-background {
  background-image: url("../assets/background.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
}
</style>
